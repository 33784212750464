import React from 'react';

const Gifts: React.FC = () => {
    
    const gifts = localStorage.getItem('gifts');
    let gift_text = 'juodo šokolado plytelė, riešutų sviestas, riešutai, medus';
    if (gifts !== null) {
        gift_text = gifts;
    }
    return (
        <div>
            <h2>Dovanos</h2>
            <p className='concept'>Mums svarbiausias yra jūsų buvimas, 
                tad didžiausia dovana - gera nuotaika ir nusiteikimas švęsti!
                </p>
            <p className='concept'>
                O jei norite dovanai suteikti fizinį pavidalą, pinigus priimtume su 
                dideliu dėkingumu. </p>
            <p className='concept'>
                Gėles skatiname iškeisti į maistą. 
                Taip, supratote teisingai! Mus džiugintų {gift_text}. </p>
        </div>
    );
};

export default Gifts;