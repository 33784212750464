import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './../firebaseConfig'; // Your Firebase config file
import { doc, getDoc, updateDoc, serverTimestamp,  where, query, collection, getDocs } from 'firebase/firestore';
import { decryptData } from './Decription';

type UserData = {
  uid: string;
  email: string | null;
  name: string | null;
  invited: number | null;
  gifts: string | null;
  arrival: string | null;
  attendance: string | null;

};

// Define a type for the context value
type AuthContextType = {
  user: UserData | null;
  answer: string | null;
  setAnswer: (answer: string) => void;
  loading: boolean;
};

// Initialize the context with default value
const AuthContext = createContext<AuthContextType>({ user: null,  loading: true, answer: null, setAnswer: () => {} });

// AuthProvider component to wrap around your app
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [answer, setAnswer] = useState<string>("no");


  const recordLoginTimestamp = async (user_uid: string) => {
    try {
      const encryptedData = localStorage.getItem('token_used');
      const secretToken = decryptData(encryptedData);
      updateLocalStorage(secretToken);
      const userRef = doc(db, 'users', user_uid);

      // Update or set user document with the login timestamp
      await updateDoc(userRef, { lastLogin: serverTimestamp()});
      console.log(`Login time recorded for user ${user_uid}`);
    } catch (error) {
      console.error('Error recording login timestamp:', error);
    }
  };

const updateLocalStorage = async (token: string) => {
    const tokenDocRef = doc(db, 'tokens', token);
    const tokenDoc = await getDoc(tokenDocRef);
    if (tokenDoc.exists()) {

      const invited = tokenDoc.data().invited;
      localStorage.setItem('invited', invited.length);

      const gifts = tokenDoc.data().gifts || null;
      if (gifts) {
        localStorage.setItem('gifts', gifts);
      }

      const arrival = tokenDoc.data().arrival || null;
      if (arrival) {
        localStorage.setItem('arrival', arrival);
      }
    }

    const attendance_collection = collection(db, 'attendance');
    const attendDocRef = query(attendance_collection, where('token', '==', token));

    const attendDocsD = await getDocs(attendDocRef);
    let answer = "no";
    attendDocsD.forEach((doc) => {
      const a = doc.data().answer;
      answer = updateAnswer(a, answer);
    });
    console.log(answer);
    setAnswer(answer);
    

  } 

  useEffect(() => {
    // Subscribe to Firebase auth state changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
            setUser({
              uid: user.uid,
              email: user.email || null,
              name: user.displayName || null,
              invited: null,
              gifts: null,
              arrival: null,
              attendance: null
            });
            setLoading(false);
            await recordLoginTimestamp(user.uid);
        }
        else{
            setUser(null);
            setLoading(false);
        }
    });
    

    return () => unsubscribe(); // Clean up on component unmount
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading, answer, setAnswer }}>
       {loading ? loadingPage() : children}
    </AuthContext.Provider>
  );
};




// Custom hook for accessing the auth context
export const useAuth = () => useContext(AuthContext);

export function updateAnswer(a: any, answer: string) {
  if (a === "yes") {
    answer = "yes";
  }
  if (a === "part1" && answer === "no") {
    answer = "part1";
  }
  return answer;
}

function loadingPage(): React.ReactNode {
  return <div className="App"> 
  <div style={{ position: 'fixed', 
                top: 0, 
                left: 0, 
                width: '100%', 
                height: '100%',
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                backgroundColor: '#e1ddd4',}}>
      <p>Kraunama...</p> 
    </div>
  </div>;
}

