import React, { useState, useEffect, useRef  } from 'react';
import { db } from '../firebaseConfig';
import { decryptData } from './Decription'
import { doc, deleteDoc, setDoc, getDoc, serverTimestamp, where, query, collection, getDocs, updateDoc } from 'firebase/firestore';
import { useAuth, updateAnswer } from './AuthContext';
import './Attend.css';

const attendance_collection = collection(db, 'attendance');

const Attend: React.FC = () => {
  const { user, setAnswer } = useAuth();

  const [saving, setSaving] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // useRef to persist timeout I
  const attendtedCreated = useRef(false);

  const [attendees, setAttendees] = useState<{ 
    id: string; 
    token: string;
    email: string;
    name: string; 
    answer: string, 
    food:string, 
    comment: string
   }[]>([]);


   async function addNewAttendee(secretToken: string, 
    email: string | null, 
    name: string, 
    attendance_collection: any) {
    const attendee = {
      id: name,
      token: secretToken,
      email: email || '',
      name: name,
      answer: 'unknown',
      food: '',
      comment: ''
    };
    console.log("Adding new attendee", attendee.name);
    const newAttendeeDocRef = doc(attendance_collection);
    await setDoc(newAttendeeDocRef, attendee);
    attendee.id = newAttendeeDocRef.id;
    return attendee;
  }

  useEffect(() => {
    // Fetch data from Firestore
    const fetchData = async () => {
      try {
        if (user) {
          const encryptedData = localStorage.getItem('token_used');
          const secretToken = decryptData(encryptedData);
          const attendDocRef = query(attendance_collection, where('token', '==', secretToken));
          const attendDocsD = await getDocs(attendDocRef);
          const numberOfAttendees = attendDocsD.docs.length;
          const invited = Number(localStorage.getItem('invited'));

          if  (attendtedCreated.current) {
            return;
          }

          if (numberOfAttendees !== invited) {
            attendtedCreated.current = true;
            attendDocsD.forEach(async (existingDoc) => {
              // Delete the document
              await deleteDoc(doc(db, 'attendance', existingDoc.id));
              console.log('Document successfully deleted!');
            });
            const tokenDocRef = doc(db, 'tokens', secretToken);
            const tokenDoc = await getDoc(tokenDocRef);
            const tokenData = tokenDoc.data();
            const names = tokenData?.invited || [];
            for (const name of names) {
              const attendee = await addNewAttendee(secretToken, user?.email, name, attendance_collection);
              setAttendees((prev) => [...prev, attendee]);
            }
          } else {
            const attendeeList = attendDocsD.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setAttendees(attendeeList as any);
          };
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
      }
    };
    fetchData();
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  }, []);

  const handleOptionChange = (id: string, value: string) => {

    let answer = 'no';
    setAttendees((prev) =>
      prev.map((attendee) =>
      {
        for (const attendee of attendees) {
          if (attendee.id === id) {
            const attendeeDoc = doc(db, "attendance", attendee.id);
            updateDoc(attendeeDoc, { answer: value });
            attendee.answer = value;
            answer = updateAnswer(value, answer);
          }
          else{
            answer = updateAnswer(attendee.answer, answer);
          }
        }
        return attendee;
      }
      )
    );
    console.log("Answer changed to", answer);
    setAnswer(answer);
  };

  useEffect(() => {
    if (!saving) {
      saveChanges(attendees); 
    }
  }, [saving, attendees]); // Runs when attendees change & saving is false

  const handleChange  = (id: string,  property: string, value: string) => {
    setAttendees((prev) =>
      prev.map((attendee) =>
        attendee.id === id ? { ...attendee, [property]: value } : attendee
      )
    );

    setSaving(true);
    if (timeoutRef.current) clearTimeout(timeoutRef.current); // Clear previous timeout

    timeoutRef.current = setTimeout(() => {
      setSaving(false);
    }, 1000); // Save after 1 second of inactivity
  };

  const saveChanges = async (currentAttendees: { id: string; token: string; email: string; name: string; answer: string; food: string; comment: string; }[]) => {
    try {
      for (const attendee of currentAttendees) {
        const attendeeDoc = doc(db, "attendance", attendee.id);
        await setDoc(attendeeDoc, {...attendee, Timestamp:serverTimestamp()}, { merge: true });
        console.debug("Attendee's data updated");
      }
    } catch (error) {
      console.error("Error updating attendees:", error);
    } finally {
      setSaving(false);
    }

  };

  return (
    <div className="confirmation-form">
      <div className="instructions">
        <p>Praneškite apie savo dalyvavimą</p>
        <p className="answer"><i>(Informacija išsaugoma automatiškai)</i></p>
        <hr></hr>
      </div>
      

      {attendees.map((attendee, index) => (
        <div key={index} className='attendance-box'>
          <div className="attendee-box">
            <span>
              {attendee.name} 
              </span>
            <select
              value={attendee.answer}
              onChange={(e) => handleOptionChange(attendee.id, e.target.value)}
              className="food-dropdown"
            >
              { attendee.answer === "unknown" && <option value="unknown">Ar dalyvausite šventėje?</option> }
              {/* <option value="unknown">Ar dalyvausite šventėje?</option> */}
              <option value="yes">Dalyvausiu visoje šventėje</option>
              <option value="part1">Atvyksiu tik į bažnyčią</option>
              <option value="no">Nedalyvausiu</option>

            </select>
          </div>

          {attendee.answer === "yes" && (
            <div>

              <textarea
                value={attendee.food}
                onChange={(e) => handleChange(attendee.id, 'food', e.target.value) }
                placeholder="Pasidalinkite, jeigu netoleruojate kokių nors maisto produktų, esate vegetaras ar veganas, kt."
                className="comment-box"
                required
              />
              {/* <textarea
                value={attendee.comment}
                onChange={e => { handleChange(attendee.id, 'comment', e.target.value) }}
                placeholder="Papildomi komentarai"
                className="comment-box"
                required
              /> */}
              <div className="answer"><p>Valio, lauksime! <br></br> Laikus ir adresus rasite 
              skiltyje Programa </p></div>
              <hr></hr>

            </div>
          )}
          {attendee.answer === "part1" && (
          <div className="answer">Susitikime 12:30 <a href="https://maps.app.goo.gl/Bn35hMzMNGcgRQCx6" target="_blank">bažnyčioje</a>!</div>        
          )}
          {attendee.answer === "no" && (
            <div className="answer">Ačiū, susitikime kitą kartą!</div>
          )} 
        </div>

      ))}


    {saving && <div><p className="text-sm text-gray-500">Išsaugoma</p></div>}

    </div>
  );
};
export default Attend;


