import React from 'react';
import { useState } from "react";


const faqs = [
    { question: "Girdėjau, kad nebus fotografo, ar tai tiesa?", 
      answer: "Taip, dedikuoto fotografo neturėsime. Norime džiaugtis spontaniškomis, natūraliomis ir smagiomis nuotraukomis, tad kviesime šventės metu naudoti savo įrenginius akimirkoms įamžinti. Po šventės sukelsime visas nuotraukas į vieną vietą ir taip visi kartu sukursime Gegužinės albumą." },
    { question: "Man vis tiek nėra aišku, kaip rengtis?", 
      answer: "Pirmojoje dalyje (bažnyčioje) galite apsirengti, kaip jums norisi! Antroje dalyje (Farmers Circle) taip pat kviečiame rengtis, kaip norite. Visgi rekomenduojame rinktis šventinį patogumą (patogi avalynė ir per daug judesių nevaržanti apranga). " },
    { question: "Ar tiesa, kad šventėje nebus alkoholio?", answer: "Taip, tiesa, pataupėm (visų sveikatos ir kito ryto savijautos sąskaita :))" },
    { question: "Turite daugiau klausimų?", answer: "Susisiekite su mumis (skambinkite arba rašykite)." }
  ];

const Faq: React.FC = () => {

    const PlusIcon = () => (
        <svg width="14" height="14" viewBox="0 0 22 22" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      );
      
      const MinusIcon = () => (
        <svg width="14" height="14" viewBox="0 0 22 22" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      );

    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const toggleFAQ = (index: number) => {
      setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="container">
        <div className="paragraph">
          {faqs.map((faq, index) => (
            <div
              key={index}
              onClick={() => toggleFAQ(index)}
            >
              <div className="left">
                <h3  className="left">{openIndex === index ? <MinusIcon /> : <PlusIcon />} {faq.question}</h3>
              </div>
              {openIndex === index && (
                <p className="concept">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
        </div>
    );
};

export default Faq;