import React from 'react';
import styles from './Agenda.module.css';
import { useAuth } from './AuthContext';

const Agenda: React.FC = () => {

    const { answer } = useAuth();
    const arrival_time = localStorage.getItem('arrival');
    const arrival_time_string = arrival_time ? arrival_time : '16:00';
    let agendaItems = [];

    if (answer === 'yes') {
        agendaItems = [
            { time: '12:30 - 15:00', title: 'Santuokos ceremonija', description: 'Antakalnio g. 27, Vilnius', 
                url: 'https://maps.app.goo.gl/Bn35hMzMNGcgRQCx6', 
                subschedule: [
                    { time: '12:30', title: 'Mišios' },
                    { time: '13:30', title: 'Bendra nuotrauka'},  
                    { time: '13:45', title: 'Užkandžiai'},  ]},  
            { time: arrival_time_string + ' - 23:00', title: 'Šventė', description: 'Farmers Circle',
                url: 'https://maps.app.goo.gl/LgSJHfnkTwDhkkuR7', subschedule: [
                    { time: '16:00', title: 'Atvykimas - sveikinimai', description: ''},
                    { time: '16:40', title: 'Įžanga', description: '' },
                    { time: '17:00', title: 'Veikla I', description: '' },
                    { time: '18:00', title: 'Vakarienė', description: '' },
                    { time: '19:30', title: 'Veikla II', description: '' },
                    { time: '20:30', title: 'Tortas', description: '' },
                    { time: '20:45', title: 'Šokiai/Laisvas laikas', description: '' }] },
            { time: '23:00', title: 'Pabaiga', description: 'Namų link', url: 'https://youtu.be/6F5IDAhkN3w?si=S0LyIVsIrdW5JJa3&t=59' }
    
        ];


    } else if (answer === 'part1') {
        agendaItems = [
            { time: '12:30 - 15:00', title: 'Santuokos ceremonija', description: 'Antakalnio g. 27, Vilnius', 
                url: 'https://maps.app.goo.gl/Bn35hMzMNGcgRQCx6', 
                subschedule: [
                    { time: '12:30', title: 'Mišios' },
                    { time: '13:30', title: 'Bendra nuotrauka'},  
                    { time: '13:45', title: 'Užkandžiai/Sveikinimai'},  ]},  
    ];
    } else {
        agendaItems = [
            { time: '12:30', title: 'Pradžia', description: 'Antakalnio g. 27, Vilnius', 
                url: 'https://maps.app.goo.gl/Bn35hMzMNGcgRQCx6' },
            { time: arrival_time_string, title: 'Atvykimas', description: 'Farmers Circle',
                url: 'https://maps.app.goo.gl/LgSJHfnkTwDhkkuR7'},
            { time: '23:00', title: 'Pabaiga', description: 'Namų link', url: 'https://youtu.be/6F5IDAhkN3w?si=S0LyIVsIrdW5JJa3&t=59' }
        ];
    }

    return (
        <div>
            <h2>Gegužės 17 programa</h2>
            <div className={styles.agendacontainer}>
                <ul className={styles.agendalist}>
                    {agendaItems.map((item, index) => (
                        <li key={index}>
                            <div className={styles.agendaitem}>
                            <div className={styles.agendatime}>{item.time}</div>
                            <div className={styles.agendadetails}>
                                <h4>{item.title}</h4>
                                <a href={item.url} target="_blank">{item.description}</a>
                            </div>
                            </div>
                            {'subschedule' in item && item.subschedule && (
                                <ul className={styles.subschedule}>
                                    {item.subschedule.map((subitem, subindex) => (
                                        <li key={subindex} className={styles.agendaitem}>
                                            <div className={styles.agendatimeul}>{subitem.time}</div>
                                            <div className={styles.agendadetails}>
                                                <p>{subitem.title}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Agenda;