import React from 'react';
import Menu from './components/Menu';
import Footer from './components/Footer';

const ProtectedPage: React.FC = () => {

    return (
        <div className="App Protected">
            <h1 className='geguzine'>Gegužinė</h1>
            <Menu />
            <Footer />
        </div>
    );
    
};

export default ProtectedPage;