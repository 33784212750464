import React, { useState } from 'react';
import activity from '../assets/cycling.png';
import dress_code from '../assets/dress_code.png';
import sitting from '../assets/sitting.png';
import shoes from '../assets/shoes.png'
import Moon from '../assets/moon.png';
import Together from '../assets/auth.png';
import styles from './Menu.module.css'; // Import as `styles` object
import Agenda from './Agenda';
import About from './About';
import Gifts from './Gifts';
import Faq from './Faq';
import DressCode from './DressCode';
import Attend from './Attend';
import { useAuth } from './AuthContext';


type MenuItem = {
    id: string;
    icon: string;
    content: JSX.Element;
    text: string
  };

const Menu: React.FC = () => {

     // Define the menu items with icons and corresponding content
  const menuItems: MenuItem[] = [
    {
      id: 'concept',
      icon: shoes,
      content: <About/>,
      text: "Idėja"
    },
    {
      id: 'program',
      icon: sitting,
      content: <Agenda/>,
      text: "Programa"
    },
    {
      id: 'attend',
      icon: Together,
      content: <Attend/>,
      text: "Dalyviai"
    },
    {
      id: 'dress_code',
      icon: dress_code,
      content: <DressCode/>,
      text: "Apranga"
    },
    {
      id: 'agenda',
      icon: activity,
      content: <Gifts/>,
      text: "Dovanos"
    },
    {
      id: 'contact_us',
      icon: Moon,
      content: <Faq/>,
      text: "D.U.K."
    },


  ];

  // State to track the currently selected menu item
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>(menuItems[0].id);

  // Find the content for the currently selected menu item
  const selectedContent = menuItems.find((item) => item.id === selectedMenuItem)?.content;

  const { answer } = useAuth();

    return (
        <div className={styles.menu}>
        {/* Render the menu icons */}
        <nav style={{ display: 'flex', justifyContent: 'space-around', padding: '0px'}}>
        {menuItems.slice(0, 3).map((item) => (
                <button
                key={item.id}
                onClick={() => setSelectedMenuItem(item.id)}
                className={selectedMenuItem  === item.id ? styles.selected : styles.mybutton }
                >
                <img
                className={selectedMenuItem  === item.id ? styles.selected_menuimg : styles.menuimg}
                src={item.icon}
                alt={`${item.id} icon`}
                />
                <p className={styles.menutext}>{item.text}</p>
                </button>
        ))}
        </nav>

        {answer === "yes" && (

        <nav style={{ display: 'flex', justifyContent: 'space-around', padding: '0px'}}>
        {menuItems.slice(3, 6).map((item) => (
                <button
                key={item.id}
                onClick={() => setSelectedMenuItem(item.id)}
                className={selectedMenuItem  === item.id ? styles.selected : styles.mybutton }
                >
                <img
                className={selectedMenuItem  === item.id ? styles.selected_menuimg : styles.menuimg}
                src={item.icon}
                alt={`${item.id} icon`}
                />
                <p className={styles.menutext}>{item.text}</p>
                </button>
        ))}
        </nav>
       )
        } 
        {/* Render the selected content */}
        <main style={{ padding: '1rem', width: '360px' }}>
        {selectedContent}
        </main>
        </div>
    );

    
};

export default Menu;